<template>
  <div class="w-100 d-flex flex-column">
    <div class="d-flex flex-row justify-content-around">
      <div class="col-12 p-0">
        <validation-provider
          #default="{ errors }"
          name="Address Line 1"
          rules="min:3"
        >
          <k-form-group
            class="m-1"
            label-content="Address Line 1"
            label-class="font-work-sans"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <k-form-input
              v-model="localAddressData.addressLine1"
              type="text"
              placeholder="Enter Address Line 1"
              :state="errors.length > 0 ? false : null"
              :disabled="disabledAllFields"
            />
          </k-form-group>
        </validation-provider>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-around">
      <div
        class="col-6 p-0"
        :class="{'col-12':isUserDetailPage}"
      >
        <k-form-group
          class="m-1"
          label-content="Address Line 2"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="localAddressData.addressLine2"
            type="text"
            placeholder="Enter Address Line 2"
            :disabled="disabledAllFields"
          />
        </k-form-group>
      </div>
      <div
        v-if="!isUserDetailPage"
        class="col-6 p-0"
      >
        <k-form-group
          class="m-1"
          label-content="Company Name"
          label-class="font-work-sans"
        >
          <k-form-input
            v-model="localAddressData.companyName"
            type="text"
            placeholder="Enter Company Name"
            :disabled="disabledAllFields"
          />
        </k-form-group>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-around">
      <div class="col-6 p-0">
        <validation-provider
          #default="{ errors }"
          name="City"
          rules="min:3"
        >
          <k-form-group
            class="m-1"
            label-content="City"
            label-class="font-work-sans"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <k-form-input
              v-model="localAddressData.city"
              type="text"
              placeholder="Enter City"
              :state="errors.length > 0 ? false : null"
              :disabled="disabledAllFields"
            />
          </k-form-group>
        </validation-provider>
      </div>
      <div class="col-6 p-0">
        <k-form-group
          class="m-1"
          label-content="Country"
          label-class="font-work-sans"
        >
          <b-dropdown
            class="w-100 k-search-dropdown dropdown-custom-class"
            :class="localAddressData.country ? 'has-value' : ''"
            variant="none"
            toggle-class="drop-caret-icon"
            :text="localAddressData.country || 'Select a Country'"
            :disabled="disabledAllFields"
          >
            <b-dropdown-item
              v-for="country in countries"
              :key="country"
              :active="localAddressData.country === country"
              @click="localAddressData.country = country"
            >
              <span>
                {{ country }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </k-form-group>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-around">
      <div class="col-6 p-0">
        <validation-provider
          #default="{ errors }"
          name="Zip Code"
        >
          <k-form-group
            class="m-1"
            label-content="Zip Code"
            label-class="font-work-sans"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <k-form-input
              v-model="localAddressData.zipcode"
              type="text"
              placeholder="Enter Zip Code"
              :state="errors.length > 0 ? false : null"
              :disabled="disabledAllFields"
            />
          </k-form-group>
        </validation-provider>
      </div>
      <div class="col-6 p-0">
        <validation-provider
          #default="{ errors }"
          name="Contact Name"
          rules="min:3"
        >
          <k-form-group
            class="m-1"
            :label-content="`${ addressType.toLowerCase() } Contact Name`"
            label-class="font-work-sans text-capitalize"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <k-form-input
              v-model="localAddressData.contactName"
              type="text"
              placeholder="Enter Contact Name"
              :state="errors.length > 0 ? false : null"
              :disabled="disabledAllFields"
            />
          </k-form-group>
        </validation-provider>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-around">
      <div class="col-6 p-0">
        <validation-provider
          #default="{ errors }"
          name="Contact Number"
          rules="phNo"
        >
          <k-form-group
            class="m-1 phone-contact-input font-inter"
            :label-content="`${ addressType.toLowerCase() } Contact Number`"
            label-class="font-work-sans text-capitalize"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <vue-tel-input
              v-model="localAddressData.contactNumber"
              mode="international"
              placeholder="Enter Contact Number"
              :state="errors.length > 0 ? false : null"
              :disabled="disabledAllFields"
            />
          </k-form-group>
        </validation-provider>
      </div>
      <div class="col-6 p-0">
        <validation-provider
          #default="{ errors }"
          name="Contact Email"
          rules="email"
        >
          <k-form-group
            class="m-1"
            :label-content="`${ addressType.toLowerCase() } Contact Email`"
            label-class="font-work-sans text-capitalize"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <k-form-input
              v-model="localAddressData.contactEmail"
              type="email"
              placeholder="Enter Contact Email"
              :state="errors.length > 0 ? false : null"
              :disabled="disabledAllFields"
            />
          </k-form-group>
        </validation-provider>
      </div>
    </div>

    <div
      v-if="addressType === ADDRESSES_TYPE.BILLING && !isUserDetailPage"
      class="d-flex flex-row justify-content-around"
    >
      <div class="col-6 p-0">
        <k-form-group
          class="m-1"
          label-content="Trade License Number"
          label-class="font-work-sans text-capitalize"
        >
          <k-form-input
            v-model="localAddressData.tradeLicenseNumber"
            type="text"
            placeholder="Enter Trade License Number"
            :disabled="disabledAllFields"
          />
        </k-form-group>
      </div>
      <div class="col-6 p-0">
        <k-form-group
          class="m-1"
          label-content="VAT Certificate Number"
          label-class="font-work-sans text-capitalize"
        >
          <k-form-input
            v-model="localAddressData.vatCertificateNumber"
            type="text"
            placeholder="Enter VAT Certificate Number"
            :disabled="disabledAllFields"
          />
        </k-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { ADDRESSES_TYPE } from '@/constants'
import { KFormInput, KFormGroup } from '@kingpin-global/kingpin-ui'
import { ValidationProvider } from 'vee-validate'
import {
  min,
  phNo,
  email,
} from '@/@core/utils/validations/validations'

export default {
  name: 'AddressForm',
  components: {
    BDropdown,
    BDropdownItem,
    KFormInput,
    KFormGroup,
    ValidationProvider,
  },
  props: {
    addressType: {
      type: String,
      default() {
        return ''
      },
    },
    addressData: {
      type: Object,
      default() {
        return {}
      },
    },
    countries: {
      type: Array,
      default() {
        return []
      },
    },
    disabledAllFields: {
      type: Boolean,
      default() {
        return false
      },
    },
    isUserDetailPage: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      localAddressData: {},
      ADDRESSES_TYPE,
      // for validation
      min,
      phNo,
      email,
    }
  },
  emits: ['address-updated'],
  watch: {
    localAddressData: {
      handler(val) {
        this.$emit('address-updated', val)
      },
      deep: true,
    },
  },
  created() {
    this.setAddress(this.addressData)
  },
  methods: {
    setAddress(data) {
      this.localAddressData = {}
      switch (this.addressType) {
        case ADDRESSES_TYPE.BILLING:
          if (this.isUserDetailPage) {
            this.localAddressData = data
          } else {
            this.localAddressData = {
              addressLine1: data.billingAddressLine1,
              addressLine2: data.billingAddressLine2,
              city: data.billingCity,
              country: data.billingCountry,
              contactName: data.billingContactName,
              contactNumber: data.billingContactNumber,
              contactEmail: data.billingContactEmail || '',
              zipcode: data.billingZipcode,
              companyName: data.companyName || '',
              tradeLicenseNumber: data.tradeLicenseNumber,
              vatCertificateNumber: data.vatCertificateNumber,
            }
          }
          break
        case ADDRESSES_TYPE.SHIPPING:
          if (this.isUserDetailPage) {
            this.localAddressData = data
          } else {
            this.localAddressData = {
              addressLine1: data.shippingAddressLine1,
              addressLine2: data.shippingAddressLine2,
              city: data.shippingCity,
              country: data.shippingCountry,
              contactName: data.shippingContactName,
              contactNumber: data.shippingContactNumber,
              contactEmail: data.shippingContactEmail || '',
              zipcode: data.shippingZipcode,
              companyName: data.companyName || '',
              tradeLicenseNumber: null,
              vatCertificateNumber: null,
            }
          }
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/@core/scss/vue/components/address-form.scss";
</style>
