<template>
  <svg
    width="8"
    height="7"
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.88888 7L-6.67572e-06 -3.39977e-07L7.77777 0L3.88888 7Z"
      fill="#373D6E"
    />
  </svg>
</template>
<script>
export default {
  name: 'CaretSvgIcon',
}
</script>
